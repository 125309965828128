export const ADMIN = 'admin';
export const EMPLOYEE = 'employee';
export const MEMBER = 'member';
export const PROVIDER = 'provider';
export const SUPERVISOR = 'supervisor';

export const userRoles = {
  EMPLOYEE,
  MEMBER,
  SUPERVISOR,
};

export const roleLevels = [MEMBER, EMPLOYEE, SUPERVISOR, PROVIDER, ADMIN];
